<template>
    <div class="box infoAlert">
        <h4 style="font-size: 1.4rem; color:#fff; font-weight:300;margin-bottom: 10px;">접속로그</h4>
        <div class="logInfo-list" style="overflow-y: auto; height: 130px;max-height: 130px;background: #3c3c3c;">
            <div class="InfoDt" v-for="(data, i) in eventLogList" :key="'eventLog' + i">
                <div style="width: 100%;display: flex;align-items: center;">
                    <img src="@/assets/images/icon_svg/menber_icon.png" alt="icon" style="margin-right: 5px;" />
                    <span>
                        {{ data.showTitle }} -
                    </span>
                    <span>
                        {{ data.showDate }}
                    </span>
                </div>
                <div style="width: 100%;padding-top: 5px;padding-left: 17px;">
                    <span>
                        {{ data.showMessage }}
                    </span>
                </div>
            </div>
            <div class="InfoDt" v-if="eventLogList.length == 0">
                <span>
                    {{ $t("flight-alarm-none") }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {fetchEventLogList, changeEventLogData} from "@/api/event";
export default {
    name: "FlightAlertModal",
    data() {
        return {
            eventId: null,
            eventLogList: null
        }
    },
    created() {
        this.eventId = this.$route.params.eventId
        this.eventLogList = []
        this.getEventLogList()
    },
    mounted() {
        
    },
    methods: {
        getEventLogList() {
            let params = {
                page_size: 30,
                eventId: this.eventId,
            };
            fetchEventLogList(params).then((res) => {
                // if (this.$refs.map == undefined) {
                //     return;
                // }
                console.log(res)
                let list = changeEventLogData(res.data.data.content, this.eventLogList);
                
                if (list != null) {
                    this.eventLogList = list;
                }
            });
        },
    }

}
</script>

<style scoped>
.infoAlert{
  width: 300px ;
  height: 180px;
  padding: 10px 15px;
  z-index: 1000;
  /* position: absolute; */
  /* top: 100%;
  left: 20px; */
  border-radius: 5px;
  
  margin: 0;
  background: rgba(16, 16, 16, 0.82);
  border: 0;
  pointer-events: auto;
}
</style>