<template>
   <!-- style="z-index:2; position: absolute" :style="[{ 'top':  getMapUserCenterTop + lngLatShowUnder + positionAndLienUnder + 'px' }, isLeftPosition == true? {'left' :defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'}]" -->
  <div class="map-btn">
    <div class="small" style="display: contents" @click="moveCenter">
      <img src="@/assets/images/map/ic_map_user_center.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
import {MapMixins} from "../mixins/MapMixins";
import mapButtonEventBus from "../mapButtonEventBus";
import {getIpGet, getIpLatLng} from "@/util/common";

export default {
  name: "MapUserCenter",
  components: {  },
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,

      ipToLat : null,
      ipToLng : null
    };
  },
  mounted() {
    getIpGet().then((res) => {
      getIpLatLng(res.data.ip).then((res) => {
        this.ipToLat = res.data.latitude;
        this.ipToLng = res.data.longitude;
      });
    });
  },
  created() {
  },
  computed : {
    
    // getMapUserCenterTop(){
    //   return this.$store.getters["map/getMapUserCenterTop"];
    // },
  },
  
  methods: {
    moveCenter() {
      const ip = {
        ipToLat : this.ipToLat,
        ipToLng : this.ipToLng
      }
      mapButtonEventBus.$emit("button/moveMapUserCenter",(ip))
    },
  },
};
</script>

<style>
.map-btn4 {
  width: 54px;
  height: 54px;
}
.map-btn4 .small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
