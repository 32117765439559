<template>
    <div class="map-btn-component">
        <div class="map-btn-list">
            <!-- 현 위치 & 이동경로 -->
            <mapPositionAndLine v-if="isShowMapPositionAndLine" />
            <!-- 맵센터 이동 버튼 -->
            <mapCenter v-if="isShowMapCenter" :eventId="eventId" />
            <!-- 맵타입 버튼  -->
            <mapTypeChange v-if="isShowMapTypeChange" />
            <!-- 맵좌표등록 버튼 -->
            <mapPointShow v-if="isShowMapPoint" />
            <!-- 현장위치이동 버튼 -->
            <mapUserCenter v-if="isShowMapUserCenter" />
            <!-- 접속로그 버튼 -->
            <mapFlightAlert v-if="isShowMapFlightAlert" />
            <!-- 공역? -->
            <mapNoFlightArea v-if="isShowMapNoFlightArea" />
            <!-- 비행중인 드론으로 시점 고정 -->
            <mapFollow v-if="isShowFollow" />    
        </div>

        <WeatherInfo v-if="isShowWeather" ref="weatherInfo" />
        <!-- 맵좌표등록 모달 -->
        <MapNewPoint  />
        <!-- 접속로그 모달 -->
        <FlightAlertModal v-if="isShowFlightAlertModal" />        
        <!-- 맵타입 모달 -->
        <MapTypeSelector/>
        
    </div>
</template>
<script>
import mapPositionAndLine from "@/components/map/button/MapPositionAndLine";
import mapTypeChange from "@/components/map/button/MapTypeChange";
import mapPointShow from "@/components/map/button/MapPointShow";
import mapCenter from "@/components/map/button/MapCenter";
import mapUserCenter from "@/components/map/button/MapUserCenter";
import mapFlightAlert from "@/components/map/button/MapFlightAlert";
import mapNoFlightArea from "@/components/map/button/MapNoFlightArea";
import MapTypeChange from "@/components/map/button/MapTypeChange.vue";
import MapFollow from "@/components/map/button/MapFollow.vue";
// 모달
import MapTypeSelector from "@/components/map/button/modal/MapTypeSelector.vue";
import MapNewPoint from "@/components/map/button/modal/MapNewPoint.vue";
import FlightAlertModal from "@/components/map/button/modal/FlightAlertModal.vue";
import WeatherInfo from "@/components/weather/WeatherInfo";

import mapButtonEventBus from "@/components/map/mapButtonEventBus";

/**
 * liveListControlUser 에서는 현장을 선택할수있음.
 * 그렇기에 아직은 URL에 eventId값이 없음.
 * 그래서 현장을 클릭했을때 그 현장의 eventId를 props로 넘겨줌.
 */
export default {
    name: 'MapButtonComponent',
    
    components: {
        MapTypeChange,
        mapPositionAndLine,
        MapTypeSelector,
        mapTypeChange,
        mapPointShow,
        mapCenter,
        MapFollow,
        mapUserCenter,
        mapFlightAlert,
        mapNoFlightArea,
        MapNewPoint,
        FlightAlertModal,
        WeatherInfo
    },
    data() {
        return {
            // eventId
            eventId: null,

            // 현위치 & 이동경로
            isShowMapPositionAndLine: false,
            
            // 맵타입 변경버튼
            isShowMapTypeChange: false,
            // ??
            isShowMapPoint: false,
            // 센터이동 버튼
            isShowMapCenter: false,
            // 현장이동 버튼
            isShowMapUserCenter: false,
            // 접속로그 on/off
            isShowMapFlightAlert: false,
            // ??
            isShowMapNoFlightArea: false,
            
            // 날씨버튼 ㅋㅋ
            isShowWeather: false,
            // 따라가기 버튼
            isShowFollow: false,
            isShowFlightAlertModal: false
        }

    },
    created() {
        mapButtonEventBus.$on("button/FlightAlertShow", (isShow) => {
            this.isShowFlightAlertModal = isShow
        })

    },
    methods: {
        showMapPositionAndLine() {
            this.isShowMapPositionAndLine = true
        },
        
        showMapTypeChange() {
            // 맵타입 변경버튼
            this.isShowMapTypeChange = true
        },
        showMapPoint() {
            this.isShowMapPoint = true
        },
        showMapCenter() {
            this.isShowMapCenter = true
        },
        showMapUserCenter() {
            this.isShowMapUserCenter = true
        },
        showMapFlightAlert() {
            this.isShowMapFlightAlert = true
            this.isShowFlightAlertModal = true
        },
        showMapNoFlightArea() {
            this.isShowMapNoFlightArea = true
        },
        showWeather() {
            this.isShowWeather = true
        },
        showFollow() {
            this.isShowFollow = true
        },

        
        /**
         * 스테이션에서 스위칭할때 사용하려고 만든
         */
        noShowMapTypeChange() {
            this.isShowMapTypeChange = false
        },
        noShowFollow() {
            this.isShowFollow = false
        },
        
    }
}
</script>
