<template>
  <!-- 버튼, 타입 변경 -->
  <!-- style="z-index:2; position: absolute" :style="[{'top' : getButtonTop + lngLatShowUnder + positionAndLienUnder + 'px' }, isLeftPosition == true? {'left' : defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'}]" -->
  <div class="map-btn">
    <div class="small" style="display: contents" @click="toggleShowModal">
      <img v-if="isTypeChangeModal" src="@/assets/images/map/ic_map_type_selected.svg" class="button-icon"/>
      <img v-if="!isTypeChangeModal" src="@/assets/images/map/ic_map_type.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>
import {MapMixins} from "../mixins/MapMixins";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";

export default {
  name: "MapTypeChange",
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      isTypeChangeModal : false,
      typeSelectName: "normal",
    };
  },
  mounted() {
  },
  created() {
    // MapTypeSelector에서 닫기버튼을 누를시,
    mapButtonEventBus.$on('isShowMapTypeSelector', (value)=>{
          this.isTypeChangeModal = value
        })
  },
  computed : {
    // getButtonTop() {
    //   return this.$store.getters["map/getTypeChangeButtonTop"];
    // },
  },
  watch: {
  },
  methods: {
    
    toggleShowModal() {
      this.isTypeChangeModal = !this.isTypeChangeModal
      mapButtonEventBus.$emit('isShowMapTypeSelector', this.isTypeChangeModal)
    },
  },
};
</script>

<style>
.map-btn1 {
  width: 54px;
  height: 54px;
}
.map-btn1 .small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
img.button-icon {
  
  
}
</style>
