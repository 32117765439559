<template>
  <!-- style="z-index:2; position: absolute" :style="[{ 'top':  getMapCenterTop + lngLatShowUnder + positionAndLienUnder + 'px' }, isLeftPosition == true? {'left' :defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'}]" -->
  <div class="map-btn" >
    <div class="small" style="display: contents" @click="moveToCenter">
      <img  src="@/assets/images/map/ic_site_center.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>
import { fetchEvent } from "@/api/event";
import {MapMixins} from "../mixins/MapMixins";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";

export default {
  name: "MapCenter",
  mixins : [MapMixins],
  data() {
    return {
      eventId: null,
      isCenterSelected : false,
      eventInfo:null
    };
  },
  mounted() {
  },
  created() {
    /**
     * 현장에 들어와있는경우, URL에 있는 eventId를 받음
     */
    this.eventId = this.$route.params.eventId
    this.getEvent()

    // URL liveListControlUser 에서 현장 클릭했을때
    mapButtonEventBus.$on('selectEventId', (eventId) => {
      this.eventId = eventId
      this.getEvent()
    })
  },
  
  computed : {
    // getMapCenterTop(){
    //   return this.$store.getters["map/getMapCenterTop"];
    // },
  },
  methods: {
    moveToCenter() {
      if(this.eventInfo === null) {
        alert(this.$t("alert-message-choose-site"));
      }
      mapButtonEventBus.$emit('button/moveToCenter', this.eventInfo)
    },

    async getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      await fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.eventInfo.selectId = "MapMarker";
        }
      });
    },

  },
};
</script>

<style>

.map-btn3 {
  width: 54px;
  height: 54px;
}
.map-btn3 .small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
