<template>
  <!-- style="z-index:2; position: absolute" :style="[{ 'top': getFlightAlertButtonTop + lngLatShowUnder + positionAndLienUnder + 'px'}, isLeftPosition == true? {'left' : defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'} ]" -->
  <div class="map-btn" >
    <div class="small" style="display: contents;" @click="toggleAlertButton">
      <img v-if="isFlightAlertShow" src="@/assets/images/map/ic_map_info_selected.svg" class="button-icon"/>
      <img v-if="!isFlightAlertShow" src="@/assets/images/map/ic_map_info.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";
export default {
  name: "MapFlightAlert",
  components: {  },
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,
      //버튼 전체 값 region
      isFlightAlertShow : true,
    };
  },
  mounted() {
  },
  created() {

  },
  computed : {
    ...mapState({
      isFlightAlertShowButton : state => state.map.isFlightAlertShowButton,
    }),
    // getFlightAlertButtonTop(){
    //   return this.$store.getters["map/getFlightAlertButtonTop"];
    // },
  },
  watch: {
    isFlightAlertShowButton(value) {
      if(value) {
        this.flightAlertShowModal();
      }
    },
  },
  methods: {
    toggleAlertButton(){
      this.isFlightAlertShow = !this.isFlightAlertShow
      mapButtonEventBus.$emit("button/FlightAlertShow", this.isFlightAlertShow)
    },





    
    flightAlertShowModal() {
      if(this.isFlightAlertShow) {
        this.flightAlertHideModal();
        return;
      }
      this.isFlightAlertShow = true;
      this.$emit("map-flight-alert-show-modal",this.isFlightAlertShow);
    },
    flightAlertHideModal() {
      this.isFlightAlertShow = false;
      this.$emit("map-flight-alert-hide-modal", this.isFlightAlertShow);
    },
  },
};
</script>

<style>
/* img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.map-btn5 {
  width: 54px;
  height: 54px;
}
.map-btn5 .small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
