<template>
  <div class="weather-info">
    <!-- 날씨 정보 -->
    <!-- 20240117 update -->
    <div class="weatherWrap flex" @click="onWeatherPopShow">
      <div class="cover" v-if="weatherInfo != null">
        <div class="flex">
          <div class="weather-situation">
            <img width="40px" height="40px" :src="require(`@/assets/images/icon_svg/${weatherInfo.imageBindName}.svg`)" alt="icon"/>
            <h4 >{{ weatherInfo.temperatures == undefined || weatherInfo.temperatures == null ? '-' : weatherInfo.temperatures.split(" ")[0] }}&#8451;│</h4>
          </div>
          <div class="weather-value">
            <div class="value-low">
              <div class="value-box">
                <div class="value-icon"><img src="@/assets/images/icon_svg/precipitation-icon.png" alt="icon" /></div>
                <span class="wthData"> {{ weatherInfo.rain == undefined || weatherInfo.rain == "강수없음" ? '0mm' : weatherInfo.rain }}</span>
              </div>
              <div class="value-box">
                <div class="value-icon"><img src="@/assets/images/icon_svg/windDirection_icon.png" alt="icon" /></div>
                <span class="wthData"> {{ weatherInfo.windDirectionName }}</span>
              </div>
              <div class="value-box">
                <div class="value-icon"><img src="@/assets/images/icon_svg/windSpeed_icon.png" alt="icon" /></div>
                <span class="wthData"> {{ weatherInfo.windSpeed }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 날씨 정보 Popup -->
    <transition name="fade">
      <div v-if="weatherVisible == true" class="dim on">
        <WeatherInfoPop ref = "weatherInfoPop" :weatherList="weatherList" :updatedDate="updatedDate" @close="onWeatherPopClose" @reload = "onReload"/>
      </div>
    </transition>
  </div>
</template>

<style>
.wthData{
  font-size: 1.1rem;
  color: #808080;
}
.weather-info{
  width: 330px;
  height: 36px;
  padding: 3px 10px;
  z-index: 2;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  left: 300px;
  background: rgb(255, 255, 255);
  pointer-events: auto;
}
.weather-info:hover{cursor: pointer;background: rgba(255, 255, 255, 0.9);}
.weather-info > .weatherWrap > .cover{width: 100%;}
.weather-info > .weatherWrap > .cover > h2{color: #000;font-size: 15px;margin-bottom: 20px;padding-top: 15px;}
.weather-info > .weatherWrap > .cover > .flex{display: flex;justify-content: flex-start;align-items: center;}

.weather-situation{text-align: center;display: flex;}
.weather-situation > img{width: 25px;height: 29px;}
.weather-situation > h4{font-size: 1.2rem;color: #000;font-weight: 400;line-height: 30px;margin-left: 10px;}

.weather-value{display: flex;flex-direction: column;justify-content: space-between; align-items: center;}
.weather-value > .value-low{display: flex;justify-content: space-around;align-items: center;}
.weather-value > .value-low > .value-box{display: flex; justify-content: flex-start;align-items: center;margin: 0 10px;}
.weather-value > .value-low > .value-box > .value-icon{width: 20px;}
.weather-value > .value-low > .value-box > span{color: #000;font-size: 1.2rem;margin-left: 0px;}
</style>

<script>
import moment from "moment";
import { fetchEventWeather } from "@/api/event";
import windDirection from "@/util/windDirection";
import WeatherInfoPop from "@/components/pop/WeatherInfoPop";
export default {
  components: { WeatherInfoPop },
  props: {
  },
  data() {
    return {
      moment: moment,
      eventId : null,
      weatherVisible: false,
      weatherList: [],
      weatherInfo : null,
      updatedDate: "",
    };
  },
  created() {
    this.eventId = this.$route.params.eventId
  },
  mounted() {
    this.onReload();
  },
  methods: {
    getWeatherInfo(){
      return this.weatherInfo;
    },
    onWeatherPopShow(){
      this.weatherVisible = true;
    },
    onWeatherPopClose() {
      this.weatherVisible = false;
    },
    setEventId(eventId) {
      this.eventId = eventId;
      this.onReload();
    },
    onReload() {
      this.getWeather();
    },
    getWeather() {
      if(this.eventId == null) {
        return;
      }

      fetchEventWeather(this.eventId).then((res) => {
        if(res.data.result == 0) {

          this.updatedDate = res.data.data.apiRequestDate;
          let weatherEventDataList = res.data.data.weatherEventDataList;

          let year = moment(this.updatedDate).format("YYYYMMDD");
          let hours = moment(this.updatedDate).format("HH");
          let localDate = moment(Date()).format("YYYYMMDDHH") + '00';

          if(weatherEventDataList.length > 0 ) {
            weatherEventDataList.sort(function(a,b){
              return a.weatherEventDataId.fcstDateTime - b.weatherEventDataId.fcstDateTime;
            })
          }

          for(var i = 0; i < 7; i++){
            var findHours = Number(hours) + i;
            if(findHours >= 24) {
              findHours = findHours - 24;
            }
            if(findHours.toString().length == 1) {
              findHours = '0' + findHours;
            }

            var findFcstDateTime = year +  findHours + '00';
            var filter = weatherEventDataList.filter(item => item.weatherEventDataId.fcstDateTime == findFcstDateTime);
            if(filter.length > 0 ) {
              this.weatherList.push(filter[0])
            } else {
              var empty = {
                weatherEventDataId : {
                  fcstDateTime : findFcstDateTime,
                }
              }
              this.weatherList.push(empty)
            }
          }

          var weatherInfoFilter = this.weatherList.filter(item => item.weatherEventDataId.fcstDateTime == localDate);
          this.weatherInfo = weatherInfoFilter[0];
          this.weatherInfo.imageBindName = this.imgBinding(this.weatherInfo);
          if( this.weatherInfo.windDirection != undefined) {
            this.weatherInfo.windDirectionName = this.convertWindDirection(this.weatherInfo.windDirection.split(" ")[0]) ;
          }
          this.weatherInfo.humdityBindName = this.humidityBinding(this.weatherInfo.humidity);
        }
      });
    },
    humidityBinding(inputData) {
      if(inputData == undefined) {
        return "ic-minus";
      }

      let data = Number(inputData.split(" ")[0]);
      let humidity;
      if (0 <= data && data < 20) {
        humidity = 1;
      } else if (20 <= data && data < 40) {
        humidity = 2;
      } else if (40 <= data && data < 80) {
        humidity = 3;
      } else if (80 <= data && data < 90) {
        humidity = 4;
      } else if (90 <= data && data <= 100) {
        humidity = 5;
      }

      return "humidity_"+humidity;
    },
    imgBinding(data) {
      let imgName;
      if(data.thunderstroke == undefined|| data.rainCode == undefined || data.skyCode == undefined ) {
        return "ic-minus";
      }

      if (data.thunderstroke != "0") {
        imgName = "thunder";
      } else if (data.rainCode != "0") {
        imgName = "raincode_" + data.rainCode;
      } else if (data.skyCode != "0") {
        imgName = "skycode_" + data.skyCode;
      }
      return imgName;
    },
    convertWindDirection(data) {
      return windDirection[Math.floor((Number(data) + 11.25) / 22.5)];
    },
  },
};
</script>
